import axios from "axios";
import axiosRetry from "axios-retry";

import { getEnvConfig } from "@/config/environments";
import { getSession } from "@/services/authService";

export function apiClient() {
  const jwt = getSession().jwt;

  const client = axios.create({
    baseURL: getEnvConfig().APIDomain,
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt ? `Bearer ${jwt}` : "",
      "Canopy-App": "os",
    },
  });

  // Configure the retry mechanism, but make it specific to every request
  axiosRetry(client, { retries: 0 });

  return client;
}
