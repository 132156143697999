import { useEffect, useState } from "react";

// useLocalStorage custom hook to persist state in local storage
export const useLocalStorage = <T>(key: string, initial?: T): [T | undefined, (val: T) => void, () => void] => {
  const [val, setVal] = useState<T | undefined>(initial);
  useEffect(() => {
    const val = localStorage?.getItem(key) ? localStorage?.getItem(key) ?? initial : initial;
    setVal(val as T);
  }, []);
  const setLocalVal = (val: T) => {
    localStorage.setItem(key, `${val}`);
    setVal(val);
  };
  const removeLocalVal = () => {
    localStorage.removeItem(key);
    setVal(undefined);
  };

  return [val, setLocalVal, removeLocalVal];
};
