export const forceDownload = (blob: string, filename: string) => {
  let a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const getFileNameFromUrl = (url: string) =>
  decodeURIComponent(url.split("?")[0]?.split("\\")?.pop()?.split("/")?.pop() ?? "");

// This function allows cross-original downloads, unlike the download attribute of an anchor element
export const downloadResource = async (url: string, filename?: string) => {
  try {
    const response = await fetch(url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: "cors",
    });

    const blob = await response.blob();
    forceDownload(window.URL.createObjectURL(blob), filename ?? getFileNameFromUrl(url));
  } catch (error) {
    console.warn(error);
  }
};
