import { Button, Card, DataGrid, DataGridColumn, formatAmountCents, formatMMDDYY, Text } from "@canopyinc/aura";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetLoan } from "@/api/accounts/useGetLoan";
import { useI18n, usePluginColumns, usePlugins } from "@/hooks";
import { mdash } from "@/libs/string";
import { TransactionDetailsProps } from "..";
import { Loan } from "@canopyinc/api-docs/types/ts/Loan.type";

type LoanSummaryProps = {
  columns: DataGridColumn[];
  loading: boolean;
  loan?: Loan;
  onSelectLoanId?: (loanId: string) => void;
  title?: string;
};

export const BaseLoanSummary = ({ columns, loading, loan, onSelectLoanId, title }: LoanSummaryProps) => {
  return (
    <div className="flex flex-col gap-2">
      {title != null ? <Text as="h3">{title}</Text> : null}
      <Card>
        <DataGrid
          columns={columns}
          loading={loading}
          loadingRows={1}
          onRowClick={() => loan?.line_item_id != null && onSelectLoanId?.(loan?.line_item_id)}
          rows={
            loan != null
              ? [
                  {
                    effective_at: loan?.effective_at != null ? formatMMDDYY(loan?.effective_at) : mdash,
                    transaction_id: loan?.line_item_id ?? mdash,
                    description: loan?.description ?? mdash,
                    original_amount:
                      loan?.loan_summary?.original_amount_cents != null
                        ? formatAmountCents(loan?.loan_summary?.original_amount_cents)
                        : mdash,
                    action: (
                      <Button iconOnly size="sm" variant="ghost" icon={<FontAwesomeIcon icon={faChevronRight} />} />
                    ),
                  },
                ]
              : []
          }
          testid="loan-details"
        />
      </Card>
    </div>
  );
};

export const LoanSummary = ({ onSelectLoanId, transaction }: TransactionDetailsProps) => {
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.loan_details;
  const columns = usePluginColumns(plugins?.fields);

  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.components?.transaction_details?.loan_details;

  const { loan, loading } = useGetLoan({
    accountId: transaction?.account_id,
    loanId: transaction?.belongs_to_loan_id,
  });

  return (
    <BaseLoanSummary
      columns={columns}
      loading={loading}
      loan={loan}
      onSelectLoanId={onSelectLoanId}
      title={dictionary?.title}
    />
  );
};

export const RestructureSummary = ({ onSelectLoanId, transaction }: TransactionDetailsProps) => {
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.restructure_summary;
  const columns = usePluginColumns(plugins?.fields);

  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.components?.transaction_details?.restructure_summary;

  const { loan, loading } = useGetLoan({
    accountId: transaction?.account_id,
    loanId: transaction?.restructured_to_loan_id,
  });

  return (
    <BaseLoanSummary
      columns={columns}
      loading={loading}
      loan={loan}
      onSelectLoanId={onSelectLoanId}
      title={dictionary?.title}
    />
  );
};
