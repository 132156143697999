import React, { createContext, useEffect, useState } from "react";
import merge from "lodash/merge";

import { RecursivePartial } from "@/types";
import { CanopyOsPluginsSchema } from "./types";

type PluginsProviderProps = {
  assetsUrl: string;
  children: React.ReactNode;
  plugins?: RecursivePartial<CanopyOsPluginsSchema>;
};

export type PluginsSchema = CanopyOsPluginsSchema;

const PluginsContext = createContext<RecursivePartial<CanopyOsPluginsSchema>>({});

export const PluginsProvider = ({ assetsUrl, plugins: inputPlugins, children }: PluginsProviderProps) => {
  const [plugins, setPlugins] = useState<RecursivePartial<CanopyOsPluginsSchema>>(inputPlugins ?? {});

  useEffect(() => {
    const fetchPlugins = async () => {
      const responses = await Promise.all([
        fetch(`${assetsUrl}/canopy-os/plugins_base.json`),
        fetch(`${assetsUrl}/canopy-os/plugins_custom.json`),
      ]);

      const [basePlugins, customPlugins] = await Promise.all(
        responses.map(async (response) => {
          if (response.status === 200) {
            return await response.json();
          }
        })
      );

      setPlugins(merge(basePlugins, customPlugins));
    };
    fetchPlugins();
  }, []);

  return <PluginsContext.Provider value={plugins}>{children}</PluginsContext.Provider>;
};

export default PluginsContext;
