import { BalanceSplit } from "@canopyinc/api-docs/types/ts/BalanceSplit.type";
import { useRouter } from "next/router";
import useSWR from "swr";

import { getCurrentPageNumber } from "@/libs/navigation";
import { toQueryParamString } from "@/utils/url";
import { useApi } from "../useApi";

export type GetTransactionSplitsQuery = {
  is_discarded?: boolean;
  effective_at_after?: string;
  effective_at_before?: string;
  issued_at_after?: string;
  issued_at_before?: string;
  active_before?: string;

  limit?: number;
  starting_after?: string;
  ending_before?: string;
  sort_by?: string;
};

type BalanceSplitsResponse = {
  results: BalanceSplit[];
  paging: {
    ending_before?: string;
    starting_after?: string;
    has_more?: boolean;
  };
  meta?: {
    balance_repour_dates: string[];
  };
};

export const useTransactionSplits = (
  accountId?: string,
  transactionId?: string,
  options?: GetTransactionSplitsQuery,
  currentPageNumber = 1
) => {
  const api = useApi();
  const includingDefaultParams = {
    limit: 10,
    // Only apply is_discarded filter if active_before is NOT provided
    ...(options?.active_before != null ? {} : { is_discarded: false }),
    ...options,
  };
  const queryParams = toQueryParamString(includingDefaultParams);

  const { data, error, mutate } = useSWR<BalanceSplitsResponse>(
    () =>
      accountId != null && transactionId != null
        ? `/accounts/${accountId}/transactions/${transactionId}/balance_splits?${queryParams}`
        : null,
    (url) => (url != null ? api.get(url).then((res) => res.data) : { results: [], paging: { has_more: false } }),
    { refreshInterval: 30_000 }
  );

  return {
    splits: data?.results ?? [],
    repourDates: data?.meta?.balance_repour_dates,
    pagination: {
      currentPageNumber,
      currentPage: data?.paging,
    },
    error,
    loading: !error && !data,
    refetchSplits: mutate,
  };
};
