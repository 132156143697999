import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";

import { LINE_ITEM_TYPES } from "@/constants";
import { Account, AmortizationSchedule as AmSchedule, Product } from "@/types";
import { BalanceSplits } from "./BalanceSplits";
import { LoanSummary } from "./LoanSummary";
import { ReversalSummary } from "./ReversalSummary";
import { TransactionAdjustments } from "./TransactionAdjustments";
import { TransactionSummary } from "./TransactionSummary";
import { TransactionsImpact } from "./TransactionsImpact";
import { RepaymentProgress } from "./RepaymentProgress";
import { AmortizationSchedule } from "./AmortizationSchedule";
import { ExternalFields } from "./ExternalFields";

export type TransactionDetailsProps = {
  account: Account;
  adjustment?: Transaction; // selected adjustment
  adjustments?: Transaction[]; // adjustments for transaction
  error: boolean;
  impact?: Transaction[];
  impactLoading: boolean;
  loading: boolean;
  onSelectAdjustmentId?: (adjustmentId: string) => void;
  onSelectLoanId?: (loanId: string) => void;
  onSelectTransactionId?: (transactionId: string) => void;
  schedule?: AmSchedule[];
  timeZone?: string;
  transaction?: Transaction; // selected transaction
};

export const TransactionDetails = (props: TransactionDetailsProps) => {
  const { adjustments, transaction } = props;

  const hasBalance =
    transaction?.transaction_super_type != null &&
    ["FEE", "INTEREST", "PRINCIPAL"].includes(transaction.transaction_super_type);
  const isReversal = transaction?.transaction_type === LINE_ITEM_TYPES.PAYMENT_REVERSAL;
  const isCapitalizationParentLineItem =
    transaction?.transaction_type === LINE_ITEM_TYPES.CAPITALIZATION_OF_INTEREST_AND_FEES;
  const showBalance = hasBalance && !isReversal && !isCapitalizationParentLineItem;
  const hasAdjustments = adjustments != null && adjustments.length > 0;
  const hasReversal =
    transaction?.reversed_by_transaction_id != null || transaction?.adjustment_by_transaction_id != null;

  return (
    <div className="flex flex-col gap-6">
      <TransactionSummary {...props} showBalance={showBalance} showHint={false} />
      {!isReversal ? <BalanceSplits {...props} /> : <TransactionsImpact {...props} />}
      {hasAdjustments ? <TransactionAdjustments {...props} /> : null}
      {transaction?.belongs_to_loan_id != null ? <LoanSummary {...props} /> : null}
      {/* These two components replicate v1 behavior of loan details drawer */}
      {/* This is needed as new loan drawer is v2 only */}
      {transaction?.transaction_type === "LOAN" ? <RepaymentProgress {...props} /> : null}
      {transaction?.transaction_type === "LOAN" && props.schedule != null ? <AmortizationSchedule {...props} /> : null}
      {hasReversal ? <ReversalSummary {...props} /> : null}
      <ExternalFields {...props} />
    </div>
  );
};
