import React from "react";
import { TPaginationSummary } from "@/hooks/usePagination";
import { PagerItem } from "./CursorPagination/PagerItem";
import { PaginationArrowIcon } from "./PaginationArrowIcon";

export type TPaginationProps = TPaginationSummary & {
  loading: boolean;
  className?: string;
  "data-testid"?: string;
  onNext?(): void;
  onPrev?(): void;
  onPageSelect?(p: number): void;
  containerStyles?: React.CSSProperties;
  noLink?: boolean;
};

/**
 * A cursor-based pagination nav implementation
 * ----------------------------------------------
 *  [⬅ PREV] 1 ... 4 5 [6] 7 8 ... [NEXT ➡]
 *
 * @notes
 * This attempts to provide the user with an offset-like pagination experience without
 * having the same level of information. The benefit of cursor pagination is the
 * performance of the db lookup. However, the draw-backs are the following:
 *
 *  1. The client has no knowledge of the overall number of records
 *  2. The client is unable to jump ahead to a given page (you must get there incrementally)
 *  3. Page numbers are only kept on the client, with no real association to the actual position
 *     in the database. This means even though we are keeping track of the page state on the
 *     front-end, when it changes on the backend, it will remain out of sync until you return
 *     to the initial page and start again.
 *
 * While these drawbacks may not be ideal, the overall benefit in performance is well
 * worth the sacrifice. The goal here is to temporarily replace the existing UI, but
 * to furthermore add additional functionality which provides far more value to the user
 * than offset-pagination ever will (e.g., search, filtering, sorting, etc)
 */
export function Pagination(props: TPaginationProps): JSX.Element {
  // determine if we should pager items
  const showPrev = props.currentPageNumber > 1;
  // while "?? true" for next/skip buttons may seem counter intuitive
  // keep in mind that this is only visibility, not click-ability.
  // just provides a slightly better UI between loads. The only time
  // the above becomes wrong for a flash is when we have not yet
  // received a response for both `has_more` values, but that only
  // happens on the 2 last items of the list. So we have a poor flash
  // of content on 2 of the pages rather than all of the pages.
  // todo: fix the above by preventing the UI from updating its current state until all data has been returned.

  // determine if we have the necessary data to enable clicking page items
  const enablePrev = !!props.currentPage?.ending_before && showPrev;
  const enableNext = !!props.currentPage?.starting_after && props.currentPage?.has_more;

  // show nothing if there's only 1 page
  if (props.currentPageNumber === 1 && props.currentPage?.has_more === false) {
    return <div></div>;
  }

  return (
    <div className={`flex flex-row gap-3 ${props.className}`} style={props.containerStyles}>
      {/* * * [ ⬅ PREV ] BUTTON */}
      <PagerItem
        type="button"
        noLink={props?.noLink}
        pageTitle="Previous Page"
        pageNumber={props.currentPageNumber - 1}
        cursor={props.currentPage?.ending_before}
        direction="prev"
        liClassNames="ant-pagination-prev"
        disabled={props.loading || !enablePrev}
        onClick={() => {
          props.onPrev?.();
        }}
      >
        <PaginationArrowIcon direction="prev" />
      </PagerItem>

      {/* * * [ 1 | 3n ]  current page */}
      <PagerItem
        onClick={() => {
          props.onPageSelect?.(props.currentPageNumber);
        }}
        pageNumber={props.currentPageNumber}
        selected
        disabled
      />

      {/* * * [ ➡ NEXT BUTTON ] */}
      <PagerItem
        type="button"
        noLink={props?.noLink}
        pageTitle="Next Page"
        pageNumber={props.currentPageNumber + 1}
        cursor={props.currentPage?.starting_after}
        direction="next"
        disabled={props.loading || !enableNext}
        onClick={() => props.onNext?.()}
      >
        <PaginationArrowIcon direction="next" />
      </PagerItem>
    </div>
  );
}
