import { datadogRum } from "@datadog/browser-rum";

/**
 * Safely call analytics libs to track user
 *
 * If segment has not been initialized, window.analytics will be undefined.
 */
export function identifyUser(user: { api_user_id: string; name: string; email: string }) {
  const envUserId = `${process.env.NEXT_PUBLIC_APP_ENV}-${user.api_user_id}`;

  // Segment lib uses windows.analytics
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.identify(envUserId, {
      name: user.name,
      email: user.email,
    });
  }

  datadogRum.setUser({
    id: envUserId,
    name: user.name,
    email: user.email,
  });
}

/**
 * Safely call analytics libs to track page
 *
 * If segment has not been initialized, window.analytics will be undefined.
 */
export function identifyPage(path: string) {
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.page(path);
  }
}

/**
 * Safely call analytics libs to track an event
 *
 * If segment has not been initialized, window.analytics will be undefined.
 */
export function track(event: string) {
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.track(event);
  }
}

/**
 * Safely call analytics libs to resets the session
 *
 * If segment has not been initialized, window.analytics will be undefined.
 */
export function resetAnalytics() {
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.reset();
  }
}
