/**
 * ApiContext
 * Now that urls are encoded into environmental variables, we can remove this ApiContext.
 */
import React, { useMemo, createContext, useContext, useEffect, useCallback } from "react";
import { AxiosInstance } from "axios";
import { getSession, setSession, setErrorInterceptors, removeJWT } from "@/services/authService";
import { useRouter } from "next/router";
import { apiClient } from "@/libs/axios";
import { Auth } from "@aws-amplify/auth";

/** ApiContext stores a singular http Api instance (AxiosInstance) */
const ApiContext = createContext<{
  instance: AxiosInstance;
  clearSession(api: AxiosInstance): void;
} | null>(null);

export function ApiProvider({ children }: { children: React.ReactElement }) {
  const router = useRouter();
  const jwt = getSession().jwt;
  const apic = apiClient();

  // Whenever the app loads up, initialize our runtime environment with the JWT, aka setting JWT in the Axios headers.
  useEffect(() => {
    async function run() {
      if (jwt) {
        setSession(jwt);
      }
    }

    run();
  }, [jwt]);

  const clearSession = useCallback((api: AxiosInstance) => {
    Auth.signOut();
    removeJWT();
    delete api.defaults.headers.common["Authorization"];
  }, []);

  // Initialize our Axios interceptors
  useEffect(() => {
    async function run() {
      // TODO: ADD REFRESH TOKENS?
      setErrorInterceptors(apic, (statusCode) => {
        switch (statusCode) {
          case 401:
            clearSession(apic);
            Auth.signOut();
            break;
        }
      });
    }

    run();
  }, [jwt, apic, router, clearSession]);

  const api = useMemo(() => {
    return {
      instance: apic,
      clearSession,
    };
  }, [apic, clearSession]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

/** useApiContext returns the Api instance */
export function useApiContext() {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("Please wrap your app in an ApiProvider");
  }
  return context;
}
