export const STANDARD_LINE_ITEM_TYPES = {
  AM_FEE: "AM_FEE",
  AM_INTEREST: "AM_INTEREST",
  CHARGE: "CHARGE",
  CREDIT_OFFSET: "CREDIT_OFFSET",
  CREDIT_OFFSET_FEE: "CREDIT_OFFSET_FEE",
  CREDIT_OFFSET_INTEREST: "CREDIT_OFFSET_INTEREST",
  DEBIT_OFFSET: "DEBIT_OFFSET",
  DEFERRED_INTEREST: "DEFERRED_INTEREST",
  FEE: "FEE",
  MANUAL_FEE: "MANUAL_FEE",
  INTEREST: "INTEREST",
  LATE_FEE: "LATE_FEE",
  LOAN: "LOAN",
  ORIG_FEE: "ORIG_FEE",
  ORIG_FEE_FOR_AM: "ORIG_FEE_FOR_AM",
  PAYMENT: "PAYMENT",
  PAYMENT_REVERSAL: "PAYMENT_REVERSAL",
  RECURRING_FEE: "RECURRING_FEE",
  REFUND: "REFUND",
  RETURN_CHECK_FEE: "RETURN_CHECK_FEE",
  CAPITALIZATION_OF_INTEREST_AND_FEES: "CAPITALIZATION_OF_INTEREST_AND_FEES",
} as const;

export const ADDITIONAL_LINE_ITEM_TYPES = {
  PAYMENT_SPLIT: "PAYMENT_SPLIT",
  REVERSAL_SPLIT: "REVERSAL_SPLIT",
  CAPITALIZATION_SPLIT: "CAPITALIZATION_SPLIT",
} as const;

export const LINE_ITEM_TYPES = {
  ...STANDARD_LINE_ITEM_TYPES,
  ...ADDITIONAL_LINE_ITEM_TYPES,
} as const;

export const LINE_ITEM_STATUSES = {
  AUTHORIZED: "AUTHORIZED",
  DECLINED: "DECLINED",
  INVALID: "INVALID",
  OFFSET: "OFFSET",
  PENDING: "PENDING",
  POSTED: "POSTED",
  PROCESSING: "PROCESSING",
  RETRO_VALID: "RETRO_VALID",
  REVERSED: "REVERSED",
  REFINANCED: "REFINANCED",
  ROLLED: "ROLLED",
  SETTLED: "SETTLED",
  SPLIT_INVALID: "SPLIT_INVALID",
  SPLIT_VALID: "SPLIT_VALID",
  VALID: "VALID",
  VOID: "VOID",
} as const;
