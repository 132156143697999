import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { DataGridRow, Tooltip, formatAmountCents, formatMMDDYY } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { ReactElement } from "react";

import { mdash } from "@/libs/string";
import { TransactionHighlights } from "../TransactionHighlights";
import { TransactionSummary } from "./TransactionSummary";
import { AdjustmentCell } from "./AdjustmentCell";
import { LINE_ITEM_TYPES } from "@/constants";
import Decimal from "decimal.js-light";

type BuildAdjustmentRowsInput = {
  DropdownComponent?: () => ReactElement;
  showDateHint?: boolean;
  showHint?: boolean;
  timeZone?: string;
  tooltip?: string;
  transaction: Transaction;
};

export const maybeBuildAdjustmentRows = ({
  DropdownComponent,
  showDateHint,
  showHint,
  timeZone,
  tooltip,
  transaction,
}: BuildAdjustmentRowsInput) => {
  const { issued_at, transaction_id, original_amount_cents } = transaction;

  const isCapitalizationItem = transaction.transaction_type === LINE_ITEM_TYPES.CAPITALIZATION_OF_INTEREST_AND_FEES;
  const date = issued_at != null ? formatMMDDYY(issued_at, { timeZone }) : mdash;
  const hasInterestAdjustments = (transaction.adjustments_summary?.INTEREST?.count ?? 0) > 0;
  const hasLateFeeAdjustments = (transaction.adjustments_summary?.LATE_FEE?.count ?? 0) > 0;
  const hasCapitalizationSplits = transaction.impacted_amounts != null;

  const totalCapitalizedAmounts = new Decimal(transaction.impacted_amounts?.total_fees_cents ?? 0)
    .add(transaction.impacted_amounts?.total_interest_cents ?? 0)
    .toNumber();

  return [
    {
      date,
      transaction_id,
      transaction_summary: <TransactionSummary transaction={transaction} />,
      highlights: <TransactionHighlights transaction={transaction} showHint={showHint} />,
      amount: isCapitalizationItem ? "" : formatAmountCents(original_amount_cents),
      original_amount: isCapitalizationItem ? "" : formatAmountCents(original_amount_cents),
      balance: transaction?.balance_cents != null ? formatAmountCents(transaction.balance_cents) : mdash,
      action: DropdownComponent != null ? <DropdownComponent /> : null,
    },
    ...(hasInterestAdjustments
      ? [
          {
            date: (
              <div className="flex flex-row gap-1">
                {date}
                {showDateHint ? (
                  <Tooltip content={tooltip}>
                    <FontAwesomeIcon className="cursor-pointer mt-1" icon={faClock} />
                  </Tooltip>
                ) : null}
              </div>
            ),
            transaction_id: "",
            transaction_summary: <AdjustmentCell iconType="L">Total Interest Adjustment</AdjustmentCell>,
            highlights: "",
            original_amount: formatAmountCents(
              transaction.adjustments_summary?.INTEREST?.total_adjustment_amount_cents ?? 0
            ),
            amount: formatAmountCents(transaction.adjustments_summary?.INTEREST?.total_adjustment_amount_cents ?? 0),
            action: "",
          },
        ]
      : []),
    ...(hasLateFeeAdjustments
      ? [
          {
            date: (
              <div className="flex flex-row gap-1">
                {date}
                {showDateHint ? (
                  <Tooltip content={tooltip}>
                    <FontAwesomeIcon className="cursor-pointer mt-1" icon={faClock} />
                  </Tooltip>
                ) : null}
              </div>
            ),
            transaction_id: "",
            transaction_summary: <AdjustmentCell iconType="L">Total Late Fee Adjustment</AdjustmentCell>,
            highlights: "",
            original_amount: formatAmountCents(
              transaction.adjustments_summary?.LATE_FEE?.total_adjustment_amount_cents ?? 0
            ),
            amount: formatAmountCents(transaction.adjustments_summary?.LATE_FEE?.total_adjustment_amount_cents ?? 0),
            action: "",
          },
        ]
      : []),
    ...(isCapitalizationItem && hasCapitalizationSplits
      ? ([
          totalCapitalizedAmounts
            ? {
                date: <div className="flex flex-row gap-1">{date}</div>,
                transaction_id: "",
                transaction_summary: <AdjustmentCell iconType="L">Total Principal Adjustment</AdjustmentCell>,
                highlights: "",
                amount: formatAmountCents(totalCapitalizedAmounts),
                original_amount: formatAmountCents(totalCapitalizedAmounts),
                action: "",
              }
            : null,
          transaction.impacted_amounts?.total_interest_cents
            ? {
                date: <div className="flex flex-row gap-1">{date}</div>,
                transaction_id: "",
                transaction_summary: <AdjustmentCell iconType="L">Total Capitalized Interest</AdjustmentCell>,
                highlights: "",
                amount: formatAmountCents(-transaction.impacted_amounts.total_interest_cents),
                original_amount: formatAmountCents(-transaction.impacted_amounts.total_interest_cents),
                action: "",
              }
            : null,
          transaction.impacted_amounts?.total_fees_cents
            ? {
                date: <div className="flex flex-row gap-1">{date}</div>,
                transaction_id: "",
                transaction_summary: <AdjustmentCell iconType="L">Total Capitalized Fees</AdjustmentCell>,
                highlights: "",
                amount: formatAmountCents(-transaction.impacted_amounts.total_fees_cents),
                original_amount: formatAmountCents(-transaction.impacted_amounts.total_fees_cents),
                action: "",
              }
            : null,
        ].filter(Boolean) as DataGridRow[])
      : []),
  ];
};
